import { CombSegmentDataType, SourceConfigType } from '../player.d';
import { PlayerClass } from '../player';

import { BitmovinOptusPlayer } from '..';
import { dispatchPlayerReady } from '../utilities/events';
import { errorLog, log } from 'src/utilities/console';
import { CONFIG } from 'src/config/config';
import StallDetector from '../stallDetector';
import { WatchModeLiveType, WatchModeType } from 'src/state/stores/dashboard/dashboard';
import { WatchMode } from '@optussport/fe-bitmovin-player/lib/types';
import { Analytics } from '../../Analytics';

type LoadSegmentData = Pick<CombSegmentDataType, 'assetData' | 'playbackData'>;

const getAssetUrl = (sourceConfig: SourceConfigType) =>
    sourceConfig.hls ? sourceConfig.hls : sourceConfig.dash!;

function preLoad(
    this: PlayerClass,
    assetId: string,
    sourceConfig: SourceConfigType,
    segmentData: LoadSegmentData,
    watchmode: WatchMode
) {
    BitmovinOptusPlayer.setAssetData(segmentData.assetData);
    BitmovinOptusPlayer.setPlaybackData(segmentData.playbackData);

    // Used for Ad Holiday
    BitmovinOptusPlayer.setWatchMode(watchmode);

    Analytics.logPlaybackRequested();
    this.sourceConfig = sourceConfig;
    this.ebps = true;
    this.playerState = 'LOADING';
    this.assetUrl = getAssetUrl(sourceConfig);
    this.assetId = assetId;

    // BitmovinOptusPlayer.setAppDetails(segmentData.appData);
    // BitmovinOptusPlayer.setUserDetails(segmentData.userData);
}

function postLoad(this: PlayerClass, sourceConfig: SourceConfigType) {
    log('Loading Source Config Complete!');
    this.playerState = 'LOADED';
    this.videoElm = this.player.getVideoElement();
    this.listenForPlaybackEvents();
    dispatchPlayerReady();
    log('Successfully loaded Source Config!', this.playerState);
    if (CONFIG.platform === 'Foxtel') {
        StallDetector(this.player, {
            logger: (logmsg: string) => {
                log(`[StallDetector] ${logmsg}`);
            },
        });
    }

    this.setSpeed(1);
    const assetUrl = getAssetUrl(sourceConfig);
    if (this.assetUrl !== assetUrl) {
        this.unload();
    } else {
        this.play();
    }

    // TODO: Clean up the listners
    this.on('ready', () => {
        this.playerState = 'READY';
        this.checkQuality();
        log('Player Ready', this.player);
    });

    this.on('loadeddata', () => {
        this.playerState = 'READY';
        this.checkQuality();
        log('Player Ready', this.player);
    });

    this.on('playing', () => {
        this.ebps = false;
        log('Player Playing', this.ebps);
    });
}

export function load(
    this: PlayerClass,
    assetId: string,
    sourceConfig: SourceConfigType,
    isSSAIEnabled: boolean,
    segmentData: CombSegmentDataType,
    watchmode?: WatchModeLiveType | WatchModeType | null
) {
    const assetUrl = getAssetUrl(sourceConfig);

    if (assetUrl === this.getAssetUrl() || this.playerState === 'LOADING') {
        return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
        this.setError(null);
        this.init(assetId, isSSAIEnabled, segmentData)
            .then(() => {
                log('Loading Source Config', sourceConfig);

                if (this.playerState === 'LOADING') {
                    log('Error while loading source:', 'Player loading inprogress');
                    return Promise.reject();
                }

                const sessionId = this.initTracking();
                const _load = () => {
                    this.initTracking(sessionId);
                    preLoad.call(this, assetId, sourceConfig, segmentData, watchmode as WatchMode);
                    const pl = BitmovinOptusPlayer.load(sourceConfig, this.retryCountYospaceError);

                    // after loading bitmovin load, reset the retryCountYospaceError property.
                    // The retryCountYospaceError is updated when there is yospace error i.e on OSPlayerEvent.YOSPACE_ERROR callback
                    this.retryCountYospaceError = undefined;

                    pl.then(() => {
                        // if (watchmode === 'startover') {
                        //     this.seek(Math.ceil(this.getDuration()));
                        // }
                        postLoad.call(this, sourceConfig);
                        resolve(pl);
                    }).catch((reason: any) => {
                        errorLog('Error while loading source:', reason);
                        setTimeout(() => {
                            // Set 0001 only when there is no other error from the onError call back.
                            if (!this.error) {
                                this.setError('0001');
                            }
                        }, 200);
                        reject(reason);
                    });
                };

                this.unload(false).then(_load).catch(_load);
            })
            .catch((e) => {
                errorLog(e);
            });
    });
}
