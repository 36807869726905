import { combineConfig } from 'src/utilities/config';
import { StringSet, URLSet, VersionSpecificConfig } from '../config';
import { env } from './config.all';

const segmentHisenseURLs: URLSet = {
    PROD: 'https://cdn.segment.com/analytics.js/v1/lrSlHOpMAJA24nT3tzXwNGMrXZgQmfpP/analytics.min.js',
    PP: 'https://cdn.segment.com/analytics.js/v1/RrSLrGBxe1xaIVvXRCVjobvX4T4LkZDQ/analytics.min.js',
    STAGING:
        'https://cdn.segment.com/analytics.js/v1/RrSLrGBxe1xaIVvXRCVjobvX4T4LkZDQ/analytics.min.js',
    LOCAL: 'https://cdn.segment.com/analytics.js/v1/RrSLrGBxe1xaIVvXRCVjobvX4T4LkZDQ/analytics.min.js',
};

const drModeHisenseURLs: URLSet = {
    PROD: 'https://dr.optussport.tv/assets-hisense.json',
    PP: 'https://dr.optussport.info/assets-hisense.json',
    STAGING: 'https://dr.optussport.info/assets-hisense.json',
    LOCAL: 'https://dr.optussport.info/assets-hisense.json',
};

const brazeHisenseKeys: StringSet = {
    PROD: '20befb28-c86a-40ca-85dc-ef2a4ab61047',
    PP: '1347d373-9589-4818-b38e-ad0ca02fd4a1',
    STAGING: '1347d373-9589-4818-b38e-ad0ca02fd4a1',
    LOCAL: '1347d373-9589-4818-b38e-ad0ca02fd4a1',
};

const config_hisense_version_specific: VersionSpecificConfig = {
    // x.y.0 is concidered a later version of x.y
    '0': {
        platform: 'HisenseTV',
        segmentLibUrl: segmentHisenseURLs[env],
        drModeCheckURL: drModeHisenseURLs[env],
        noAnimations: true,
        exitAppPopupEnabled: true,
        YoSpaceActiveOnLive: false,
        YoSpaceActiveOnVod: true,
        adUIEnabledOnLive: false,
        adUIEnabledOnVod: true,

        BrazeActive: true, // All users
        BrazeApiKey: brazeHisenseKeys[env],

        DPADThrottleDelta: 400,
        bitmovinPlayerConfig: {
            playback: {
                autoplay: true,
                videoCodecPriority: ['avc', 'hevc', 'av1'],
            },
        },
    },
    '6.9': {
        YoSpaceActiveOnVod: false,
        adUIEnabledOnVod: false,
        // DPADThrottleDelta: 550,
    },
};

const config_hisense = (version: string) => combineConfig(config_hisense_version_specific, version);
export default config_hisense;
