import { Config, StringSet, URLSet } from '../config';
import { env } from '../configs/config.all';

const segmentFoxtelURLs: URLSet = {
    PROD: 'https://cdn.segment.com/analytics.js/v1/Qaz1QLoPAi6VaiLaZeBdQCGzgYdjwwVB/analytics.min.js',
    PP: 'https://cdn.segment.com/analytics.js/v1/Db2soSxzEZBujhnKuqlzhFjAw1ZnX5Bv/analytics.min.js',
    STAGING:
        'https://cdn.segment.com/analytics.js/v1/Db2soSxzEZBujhnKuqlzhFjAw1ZnX5Bv/analytics.min.js',
    LOCAL: 'https://cdn.segment.com/analytics.js/v1/Db2soSxzEZBujhnKuqlzhFjAw1ZnX5Bv/analytics.min.js',
};

const drModeFoxtelURLs: URLSet = {
    PROD: 'https://dr.optussport.tv/assets-foxteltv.json',
    PP: 'https://dr.optussport.info/assets-foxteltv.json',
    STAGING: 'https://dr.optussport.info/assets-foxteltv.json',
    LOCAL: 'https://dr.optussport.info/assets-foxteltv.json',
};

const brazeFoxtelKeys: StringSet = {
    PROD: '3c9001e5-52d6-48b3-b787-e6f44483cfa9',
    PP: '424bfb3d-bdac-42fe-82fe-82cc9aff214f',
    STAGING: '424bfb3d-bdac-42fe-82fe-82cc9aff214f',
    LOCAL: '424bfb3d-bdac-42fe-82fe-82cc9aff214f',
};

const config_foxtel: Partial<Config> = {
    platform: 'Foxtel',
    segmentLibUrl: segmentFoxtelURLs[env],
    drModeCheckURL: drModeFoxtelURLs[env],
    playbackSettingsSpeedActive: false,
    playbackSupportsCmafLive: false,
    playbackSupportsCmafVod: false,
    noAnimations: true,
    exitAppPopupEnabled: true,

    YoSpaceActiveOnLive: false,
    YoSpaceActiveOnVod: true,
    adUIEnabledOnLive: false,
    adUIEnabledOnVod: true,

    dataDogReplayRate: 0, // Turning off Replay feature for Foxtel.

    BrazeApiKey: brazeFoxtelKeys[env],

    bitmovinPlayerConfig: {
        playback: {
            autoplay: true,
            videoCodecPriority: ['avc', 'av1', 'hevc'],
        },
        tweaks: {
            dword_base_media_decode_timestamps: true,
        },
    },
};

export default config_foxtel;
