import { combineConfig } from 'src/utilities/config';
import { VersionSpecificConfig, URLSet, StringSet } from '../config';
import { env } from '../configs/config.all';

const segmentLGURLs: URLSet = {
    PROD: 'https://cdn.segment.com/analytics.js/v1/GqHwwhJ7Rg2Zg8Pwcr6eiEuqnPORgHd0/analytics.min.js',
    PP: 'https://cdn.segment.com/analytics.js/v1/kD7mjFc2Qjny3TPHbBvazY3YjqW0fhGX/analytics.min.js',
    STAGING:
        'https://cdn.segment.com/analytics.js/v1/kD7mjFc2Qjny3TPHbBvazY3YjqW0fhGX/analytics.min.js',
    LOCAL: 'https://cdn.segment.com/analytics.js/v1/kD7mjFc2Qjny3TPHbBvazY3YjqW0fhGX/analytics.min.js',
};

const drModeLGURLs: URLSet = {
    PROD: 'https://dr.optussport.tv/assets-lgtv.json',
    PP: 'https://dr.optussport.info/assets-lgtv.json',
    STAGING: 'https://dr.optussport.info/assets-lgtv.json',
    LOCAL: 'https://dr.optussport.info/assets-lgtv.json',
};

const brazeLGKeys: StringSet = {
    PROD: '03d7324a-f681-4eb7-a50e-a21cd357ddc3',
    PP: '4016069c-fc58-415c-9594-069b1c59d294',
    STAGING: '4016069c-fc58-415c-9594-069b1c59d294',
    LOCAL: '4016069c-fc58-415c-9594-069b1c59d294',
};

const config_lg_version_specific: VersionSpecificConfig = {
    // x.y.0 is concidered a later version of x.y
    '0': {
        platform: 'LGTV',
        MRActive: true,
        exitAppPopupEnabled: true,
        noAnimations: true,
        segmentLibUrl: segmentLGURLs[env],
        drModeCheckURL: drModeLGURLs[env],
        playbackDrmChoice: 'playready',
        playbackSettingsSpeedActive: false,
        playbackSupportsCmafLive: false,
        playbackSupportsCmafVod: false,
        YoSpaceActiveOnLive: false,
        YoSpaceActiveOnVod: false,

        BrazeActive: true, // All users
        BrazeApiKey: brazeLGKeys[env],

        bitmovinPlayerConfig: {
            playback: {
                videoCodecPriority: ['avc', 'hevc', 'av1'],
            },
        },
    },
    '5.0': {
        noAnimations: false,
        playbackSupportsCmafLive: true,
        playbackSupportsCmafVod: true,
        YoSpaceActiveOnLive: false,
        YoSpaceActiveOnVod: true,
        adUIEnabledOnLive: false,
        adUIEnabledOnVod: true,

        bitmovinPlayerConfig: {
            playback: {
                videoCodecPriority: ['avc', 'hevc', 'av1'],
            },
            tweaks: {
                check_all_drm_licenses_for_kid: true,
                hls_clear_buffers_on_discontinuity_switches: true,
            },
        },
    },
};

const config_lg = (version: string) => combineConfig(config_lg_version_specific, version);
export default config_lg;
