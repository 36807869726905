import { UAParser } from 'ua-parser-js'

const foxtelOSs = [
    [/(WPE ARRIS_Foxtel_STB_\S+[\s]*)\/([\w.]+)/i], [UAParser.OS.NAME, UAParser.OS.VERSION,]
];

const foxtelDevices = [
    //Mozilla/5.0 (ARRIS_Foxtel_STB_DGX7000NF; Linux mipsel) AppleWebKit/605.1.15 (KHTML, like Gecko) WPE ARRIS_Foxtel_STB_DGX7000NF /1.21.0.11 (Foxtel,DGX7000NF ,wireless)
    [/(WPE ARRIS_Foxtel_STB_DGX7000NF[\s]?)\/([\w.]+)/i], [[UAParser.DEVICE.MODEL, 'iQ3'], [UAParser.DEVICE.TYPE, UAParser.DEVICE.SMARTTV], [UAParser.DEVICE.VENDOR, 'Foxtel']],

    //Mozilla/5.0 (ARRIS_Foxtel_STB_MX6505NF; Linux armv7l) AppleWebKit/605.1.15 (KHTML, like Gecko) WPE ARRIS_Foxtel_STB_MX6505NF/1.21.1.10 (Foxtel,MX6505NF,wireless)
    [/(WPE ARRIS_Foxtel_STB_MX6505NF)\/([\w.]+)/i], [[UAParser.DEVICE.MODEL, 'iQ4'], [UAParser.DEVICE.TYPE, UAParser.DEVICE.SMARTTV], [UAParser.DEVICE.VENDOR, 'Foxtel']],

    //Mozilla/5.0 (ARRIS_Foxtel_STB_QH5515ZF; Linux armv7l) AppleWebKit/605.1.15 (KHTML, like Gecko) WPE ARRIS_Foxtel_STB_QH5515ZF/1.21.2.10 (Foxtel,QH5515ZF,wireless)
    [/(WPE ARRIS_Foxtel_STB_QH5515ZF)\/([\w.]+)/i], [[UAParser.DEVICE.MODEL, 'iQ5'], [UAParser.DEVICE.TYPE, UAParser.DEVICE.SMARTTV], [UAParser.DEVICE.VENDOR, 'Foxtel']],
]

const deviceInfo = UAParser(navigator.userAgent, { os: foxtelOSs, device: foxtelDevices })
export const isFoxtel = () => {
    return deviceInfo.device.vendor === 'Foxtel'
}

export const getFoxtelModel = () => {
    return deviceInfo.device.model
}

export const getFoxtelOSVersion = () => {
    return deviceInfo.os.version
}