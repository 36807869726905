import { PlayerClass } from '../player';
import { BitmovinOptusPlayer } from '..';
import { Segment } from '../segment';

function handleSegment(this: PlayerClass) {
    if (this.zombieKicked) {
        Segment.trackZombieKickSessionFromClient();
    } else if (this.isLive()) {
        // this is to make sure to log when user has changed the channel
        Segment.handleAppKill({
            type: 'user',
            message: 'user changed linear channel',
        });
    } else {
        Segment.handleAppKill({
            type: 'user',
            message: 'user closed the player',
        });
    }
}

export function unload(this: PlayerClass, trackEvents: boolean = true) {
    this.assetUrl = null;
    this.assetId = null;
    // this.eventCbs = {};
    this.setError(null);
    if (this.player) {
        if (trackEvents) {
            const isPlaybackComplete =
                this.getDuration() && this.getCurrentTime() === this.getDuration();
            if (!isPlaybackComplete) {
                // No Events needed on playback Complete, As it is Hanlded by the FE lib
                handleSegment.call(this);
            }
        }
        this.playerState = 'UNLOADING';
        this.assetUrl = null;

        return new Promise((resolve, reject) => {
            const onUnload = () => {
                this.playerState = 'UNLOADED';
                this.zombieKicked = false;
                this.videoElm = null;
                resolve(null);
            };

            try {
                BitmovinOptusPlayer.unload().then(onUnload);
            } catch (error) {
                onUnload();
            }
        });
    }

    return Promise.resolve();
}
