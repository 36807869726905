import React, { useCallback, useRef, useState } from 'react';
import { FocusNode, useSetFocus } from 'lal-lrud';
import { useNavigate } from 'react-router-dom';
import DashboardBody from 'src/components/ui/Dashboard/DashboardBody';
import Sidebar from 'src/components/ui/Sidebar';
import useBackButton from 'src/controllers/keyPress/backButton.controller';
import useDashboard from 'src/controllers/dashboard.controller';
import { exitApp } from 'src/utilities/device';
import styles from './dashboard.module.scss';
import { useMagicRemote } from 'src/controllers/magicRemote.controller';
import useExplore from 'src/controllers/exploreRail.controller';
import OLRUDProvider from 'src/lib/OLRUD/context';
import focusIds from 'src/constants/focusIds';
import useViewAll from 'src/controllers/viewAll.controller';
import { CONFIG } from 'src/config/config';
import { useShimmer } from 'src/controllers/shimmer.controller';
import Spinner from 'src/components/Common/Spinner';
import { BannerOverlay } from 'src/components/ui/Overlays/Banner';
import FullscreenPopup from 'src/components/ui/FullscreenPopup';
import usePage from 'src/lib/OLRUD/controllers/page.controller';
import { resetCardPos } from 'src/lib/OLRUD/utilities/cardPosCache';
import { Analytics } from 'src/lib/Analytics';

const Dashboard = () => {
    useDashboard();
    const { showShimmer } = useShimmer();
    const setFocus = useSetFocus();
    const sideBarOnFocus = useRef(false);
    const [displayExitPopup, setDisplayExitPopup] = useState<boolean>(false);
    const { isExplore } = useExplore();
    const { isViewAll } = useViewAll();
    const navigate = useNavigate();
    const { pageId } = usePage();
    useMagicRemote();

    let callBack = useCallback(() => {
        if (isExplore || isViewAll) {
            setTimeout(() => {
                resetCardPos(pageId);
                navigate(-1);
            });
            return true;
        }
        if (sideBarOnFocus.current) {
            // TODO: CTV-1257 - Potentially, this feature should be moved to the Route/Index to
            // avoid dupliclate logic (found in Settings Side Bar).
            if (CONFIG.exitAppPopupEnabled) {
                setDisplayExitPopup(true);
            } else {
                exitApp();
            }
            return true;
        }
        setFocus(focusIds.sidebar);
        setDisplayExitPopup(false);
        return true;
    }, [isExplore, isViewAll, navigate, pageId, setFocus]);
    useBackButton({ callBack, id: 'dashboard-focus-sidebar', rank: 3 });

    const onSideBarFocus = (e: any) => {
        Analytics.logSideBarOpened();
        sideBarOnFocus.current = true;
    };

    const onSideBarBlur = () => {
        Analytics.logSideBarClosed();
        sideBarOnFocus.current = false;
    };

    const onPopupExit = () => {
        setDisplayExitPopup(false);
    };

    const dashboardClass = [styles.dashboard];
    if (showShimmer) {
        dashboardClass.push(styles.noAnimations);
    }

    return (
        <FocusNode className={styles.dashboard} onMountAssignFocusTo={focusIds.dashboardBody}>
            {!CONFIG.noAnimations && showShimmer && <Spinner fullScreen />}
            {displayExitPopup && (
                <FullscreenPopup
                    id={'app.exitApp'}
                    onClick={exitApp}
                    onClickSecondary={onPopupExit}
                    onExit={onPopupExit}
                />
            )}
            <FocusNode
                focusId={focusIds.sidebar}
                onFocused={onSideBarFocus}
                onBlurred={onSideBarBlur}
            >
                <Sidebar />
            </FocusNode>
            <FocusNode focusId={focusIds.dashboardBody} className={dashboardClass.join(' ')}>
                <OLRUDProvider className={styles.body}>
                    <DashboardBody />
                </OLRUDProvider>
            </FocusNode>
            <BannerOverlay></BannerOverlay>
        </FocusNode>
    );
};

export default React.memo(Dashboard);
