import { useEffect } from 'react';
import { CONFIG } from 'src/config/config';
import { getAppVersion } from 'src/utilities/app';
import { baseUrls } from 'src/config/config/configs/config.all';
import useUser from '../auth/user.controller';
import useAxiosApplyInterceptor from './app.axios.controller';
import useAuth from '../auth/auth.controller';
import { Analytics } from 'src/lib/Analytics';

const useApp = () => {
    useAxiosApplyInterceptor();

    useAuth();

    const { user } = useUser();

    useEffect(() => {
        Analytics.logAppLaunch();
        return () => {
            Analytics.logAppExit();
        };
    }, []);

    useEffect(() => {
        const baseurl = CONFIG.baseURL;
        const env = baseurl === baseUrls.PP ? 'PP' : baseurl === baseUrls.PROD ? 'PROD' : 'LOCAL';
        document.title = `[${env.slice(0, 2)}|${user ? 'U' : 'A'}|${
            CONFIG.platform
        }|${getAppVersion()}] Optus Sport - CTV App`;
    }, [user]);
};

export default useApp;
