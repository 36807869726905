import { isFoxtel } from 'src/utilities/foxtel';
import { isPS4 } from 'src/utilities/ps4';
import { isPS5 } from 'src/utilities/ps5';
import { isTizen, getTizenVersion } from 'src/utilities/tizen';
import { getVidaaOSVersion, isVidaaOS } from 'src/utilities/vidaa';
import { isWebos, getWebOSVersion } from 'src/utilities/webos';
import { isXbox } from 'src/utilities/xbox';
import { Config } from './config';
import config_all from './configs/config.all';
import config_debug from './configs/config.all.debug';
import config_foxtel from './configs/config.foxtel';
import config_hisense from './configs/config.hisense';
import config_lg from './configs/config.lg';
import config_ps5 from './configs/config.ps5';
import config_ps4 from './configs/config.ps4';
import config_samsung from './configs/config.samsung';
import config_xbox from './configs/config.xbox';
import { config_device, config_device_secondary } from '../user/config.device';
import { deviceConfigSetupSecondary, enableDDRumDeviceIds } from '../user/deviceList';
import { getDeviceId, matchByUserDevice } from 'src/utilities/device';

export const debug: boolean = process.env.REACT_APP_DEBUG?.toLocaleLowerCase() === 'true';

let config_local = {};
try {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        config_local = require('./configs/config.all.local').default;
    } else {
    }
} catch (error) {}

const deviceId = getDeviceId();

export const CONFIG: Config = {
    ...config_all,
    ...(debug ? config_debug : {}),
    ...(isFoxtel() ? config_foxtel : {}),
    ...(isPS5() ? config_ps5 : {}),
    ...(isPS4() ? config_ps4 : {}),
    ...(isTizen() ? config_samsung(getTizenVersion()) : {}),
    ...(isVidaaOS() ? config_hisense(getVidaaOSVersion()) : {}),
    ...(isWebos() ? config_lg(getWebOSVersion()) : {}),
    ...(isXbox() ? config_xbox : {}),
    ...(deviceId && matchByUserDevice(enableDDRumDeviceIds, deviceId)
        ? config_device
        : deviceId && matchByUserDevice(deviceConfigSetupSecondary, deviceId)
        ? config_device_secondary
        : {}),
    ...config_local,
};
