import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FullscreenPopup from 'src/components/ui/FullscreenPopup';
import ShowDetail from 'src/components/ui/VodDetail/Detail/ShowDetail';
import MatchDetail from 'src/components/ui/VodDetail/Detail/MatchDetail';

import useVodDetail from 'src/controllers/vodDetail.controller';
import { WatchModeLiveType } from 'src/state/stores/dashboard/dashboard';

import { checkifPastOrUpcoming } from 'src/utilities/time';
import Spinner from 'src/components/Common/Spinner';
import SubscriptionPending from 'src/components/ui/SubscriptionPending';
import { isMatchAsset } from 'src/utilities/asset';

function VodDetail() {
    const {
        asset,
        showSubPendingNotification,
        onPlayClick,
        hideNotification,
        handleAssetFetchError,
    } = useVodDetail();

    const { broadcastStartTime } = asset || {};
    const [isPastOrUpcoming, setIsPastOrUpcoming] = useState(
        checkifPastOrUpcoming(broadcastStartTime ? broadcastStartTime : 'upcoming')
    );
    const navigate = useNavigate();
    useEffect(() => {
        if (broadcastStartTime) {
            setIsPastOrUpcoming(checkifPastOrUpcoming(broadcastStartTime));
        }
    }, [broadcastStartTime]);

    const showMatchDetails = useMemo(() => {
        return asset && isMatchAsset(asset);
    }, [asset]);

    const onButtonClick = useCallback(
        (watchMode?: WatchModeLiveType) => {
            if (asset) {
                onPlayClick(asset.id, watchMode);
            }
        },
        [onPlayClick, asset]
    );

    if (handleAssetFetchError) {
        const goBack = () => {
            navigate(-1);
        };
        return (
            <FullscreenPopup
                id={'asset.genericError'}
                onClick={goBack}
                onExit={goBack}
            ></FullscreenPopup>
        );
    }

    if (!asset) {
        return <Spinner fullScreen />;
    }

    return (
        <>
            {showSubPendingNotification ? (
                <SubscriptionPending hideNotification={hideNotification} />
            ) : null}
            {showMatchDetails ? (
                <MatchDetail
                    asset={asset}
                    isPastOrUpcoming={isPastOrUpcoming}
                    setIsPastOrUpcoming={setIsPastOrUpcoming}
                    onPlayClick={onPlayClick}
                />
            ) : null}
            {!showMatchDetails ? (
                <ShowDetail
                    asset={asset}
                    isPastOrUpcoming={isPastOrUpcoming}
                    setIsPastOrUpcoming={setIsPastOrUpcoming}
                    onPlayClick={onButtonClick}
                />
            ) : null}
        </>
    );
}

export default React.memo(VodDetail);
