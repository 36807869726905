import { useCallback, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { CONFIG } from 'src/config/config';
import RouteTags from 'src/routes/routeTags';
import { reloadApp } from 'src/utilities/app';
import { clearSuspendedTime, getSuspendedTime, setSuspendedTime } from 'src/utilities/appSuspend';

import usePlayer from './player.controller';
import { Analytics } from 'src/lib/Analytics';

const useAppSuspend = () => {
    // const isDashboard = useMatch(RouteTags.dashboard);
    const isvodPlayback = useMatch(RouteTags.vodPlayback);
    // const isvodDetail = useMatch(RouteTags.vodDetail);
    const islivePlayback = useMatch(RouteTags.livePlayback);
    const player = usePlayer();
    // const { dispatch } = useStateValue();

    // const handleRestore = useCallback(() => {
    //     if (isDashboard) {
    //         dispatch(resetLayout());
    //     } else if (isvodDetail) {
    //         dispatch(resetLayout());
    //     } else if (isvodPlayback) {
    //         dispatch(resetLayout());
    //         player.Player.play();
    //     } else if (islivePlayback) {
    //         dispatch(resetLayout());
    //         player.Player.seek(0);
    //         player.Player.play();
    //     } else {
    //         reloadApp();
    //     }
    // }, [isDashboard, isvodPlayback, isvodDetail, islivePlayback, player.Player, dispatch]);

    const handleSuspend = useCallback(() => {
        setSuspendedTime();
        if (isvodPlayback) {
            player.Player.pause();
        } else if (islivePlayback) {
            player.Player.pause();
        }
    }, [isvodPlayback, islivePlayback, player.Player]);

    useEffect(() => {
        const onBackground = (e: Event) => {
            Analytics.logAppSuspended();
            handleSuspend();
        };
        const onForeground = (e: Event) => {
            Analytics.logAppResumed();
            const suspendedTime = getSuspendedTime();
            const duration = Date.now() - suspendedTime;
            if (duration > CONFIG.ASThreshold) {
                reloadApp();
            }
        };
        const handleVisibityChange = (e: Event) => {
            !document.hidden ? onForeground(e) : onBackground(e);
        };

        const loadedmsdk = () => {
            //@ts-ignore
            window.msdk.addEventListener('onBackground', onBackground);
            //@ts-ignore
            window.msdk.addEventListener('onForeground', onForeground);
        };

        const loadedmsdkRemove = () => {
            //@ts-ignore
            window.msdk.removeEventListener('onBackground', onBackground);
            //@ts-ignore
            window.msdk.removeEventListener('onForeground', onForeground);
        };

        if (CONFIG.ASActive) {
            document.addEventListener('visibilitychange', handleVisibityChange);
            window.addEventListener('loadedmsdk', loadedmsdk);
        }
        return () => {
            if (CONFIG.ASActive) {
                document.removeEventListener('visibilitychange', handleVisibityChange);
                window.removeEventListener('loadedmsdk', loadedmsdkRemove);
            }
        };
    }, [handleSuspend]);

    /* Clear on app Launch  */
    useEffect(() => {
        clearSuspendedTime();
    }, []);
    /* End: Clear on app Launch  */
};

export default useAppSuspend;
